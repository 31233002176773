// require("src/components/prismjs-tomorrow.css");

import React from "react";
import { createStore } from "redux";
import { blogState } from "./src/reducers/blogState";
import { Provider } from "react-redux";

export const wrapRootElement = ({ element }) => {
  let store = createStore(blogState);
  if (typeof window !== "undefined") {
    store = createStore(
      blogState,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }
  return <Provider store={store}>{element}</Provider>;
};
