import {features } from "../features/features";
interface AppState {
  isLoaded: boolean;
  isDark: boolean;
}

const initialState: AppState = {
  isLoaded: false,
  isDark: false,
};

const blogState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_LOADED":
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    case "TOGGLE_IS_LOADED":
      return {
        ...state,
        isLoaded: !state.isLoaded,
      };
    case "TOGGLE_THEME":
      return {
        ...state,
        isDark: !state.isDark,
      };
    case "SET_IS_DARK":
      return {
        ...state,
        isDark: action.isDark,
      };
    default:
      return state;
  }
};

export { blogState, AppState };
